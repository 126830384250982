import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataPassService} from './services/data-pass.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
  title = 'iTicket.global';
  toggler = false;
  overLayHeight = 0;

  constructor(private dataPassService: DataPassService) {
  }

  ngOnInit() {
    this.dataPassService.currentRouteMessage.subscribe( resp => {
      window.scrollTo(0, 0);

      // if (resp !== 'default') {
      //   this.overLayHeight = window.scrollY;
      //   this.toggler = true;
      //   setTimeout(() => {
      //     this.toggler = false;
      //   }, 800);
      // }
    });
  }

  listenRouteChange(event) {
    this.toggler = true;
    setTimeout(() => {
      window.scrollTo(0, 0);
      this.toggler = false;
    }, 800);
  }
}
