<section>
  <div class="next-section-wrapper w-100 text-center">
    <p class="info-text">Next section</p>
    <p class="page-title" (click)="changeRoute()">
      <span class="hover-section" #hoverSection>
              <span class="hover hover-1">{{nextSectionName}}</span>
      </span>
    </p>
  </div>
</section>



