import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NextSectionComponent} from './next-section/next-section.component';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [NextSectionComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    NextSectionComponent]
})
export class SharedModule { }
