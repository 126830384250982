import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MediaPost} from '../../media-posts/media-post';

@Component({
  selector: 'app-media-details',
  templateUrl: './media-details.component.html',
  styleUrls: ['./media-details.component.scss']
})
export class MediaDetailsComponent implements OnInit {

  mediaPosts = new MediaPost().posts;
  selectedPostDetails;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( param => {
      this.selectedPostDetails = this.mediaPosts[(+param.id) - 1];
    });
  }

}
