import {Component, ElementRef, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  contactUsRectangle = false;
  @ViewChild('togglerIcon') togglerIcon: ElementRef<any>;
  selectedRoute = '';
  hamburgerMenuOpenState = false;
  @Output() routeChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.router.events.subscribe( resp => {
       this.contactUsRectangle = (this.router.url.includes('/products') && window.innerWidth > 992);
       this.selectedRoute = this.activatedRoute.snapshot['_routerState'].url;
    });
  }


  clickOnMenuItem(route) {
    if (route !== this.selectedRoute) {
      this.routeChanged.emit('routeChanged');
      setTimeout( () => {
        this.router.navigate([route]);
      }, 750);
    }
    if (window.innerWidth <= 992 && route !== '/') {
      this.togglerIcon.nativeElement.click();
    }
  }
}
