import { Component, OnInit } from '@angular/core';
import {MediaPost} from '../../media-posts/media-post';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  mediaContents = new MediaPost().posts;

  constructor() { }

  ngOnInit(): void {
  }


}
