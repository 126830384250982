export class MediaPost {
  posts = [
    {
      img: 'assets/images/media/rebranding.jpg',
      header: 'iTicket Global launches international re-branding',
      shortDescription: 'Following iTicket Global’s successful expansion, a new iTicket Global brand identity has been launched and is now being implemented in iTicket Global’s national offices.\n',
      longDescription: 'Ziya Mammadov, CEO: “We chose Leo Burnett to re-brand our company due to their experience and expertise in creating strong brand identities. They are one of the world’s leading advertising agencies and their input and creativity has elevated our brand identity and completed an important step in our international growth strategy.”  \n' +
        'iTicket.az was launched in Baku, Azerbaijan in 2016 and within a short time became the market leader with a 95% market share. iTicket Global was established in 2018 and with it, offices in Russia, UAE and Uzbekistan were opened. 5 new offices are set to open in 2021.\n' +
        'Bettina Kuperman, CBDO: “iTicket Global is much more than a ticketing company. We are technological trailblazers with a mission to improve the ticketing industry and ensure that all parts of the world have access to innovative ticketing and e-commerce tools. Our new brand identity is an important part of our growth strategy and we are excited to roll it out in our new offices.”\n',
      author: 'Bettina Kuperman',
      createdAt: 'November 11, 2020',
      detailedViewHeader: 'We launched international re-branding'
    },
    {
      img: 'assets/images/media/covid19.png',
      header: 'Covid-19 - adjusting to a new reality',
      shortDescription: 'The Covid-19 Pandemic has been the biggest ever upset to the ticketing industry. As all events, venues, entertainment, and sports activities came to an abrupt halt, and with it the ticket sales.',
      longDescription: 'It is safe to say there will be winners and losers in the aftermath of the pandemic. 2021 will show a new reality where it will no longer be enough for ticketing agencies to act as an independent middleman or ticket brokering service. Technology has become the key word to overcome the challenges brought by Covid-19 and ticketing agencies must work together with event owners and their customers to ensure a safe event environment, create new revenue streams through digital products, use data to improve marketing and communication, and develop new technologies that limits fraud, increases sales, and creates a transparent and loyal relationship with all stakeholders. \n' +
        'We see three major trends for the coming years.\n',
      author: 'Bettina Kuperman',
      createdAt: 'November 12, 2020',
      detailedViewHeader: 'Covid-19 - adjusting to a new reality'
    },
    {
      img: 'assets/images/media/3d_map.png',
      header: 'First 3D map implementation in the ticketing world',
      shortDescription: 'iTicket.Global has developed and is now offering all our clients to showcase their venues in 3D. This feature makes it possible for ticket buyers to get a complete 360 view of the venue and the view from the seat before buying the ticket.',
      longDescription: '&nbsp;&nbsp;&nbsp;&nbsp;Ziya Mammadov, CEO iTicket.Global: “We ask our client’s for feedback and one of the main comments were, that it was difficult to choose seats and that it affected the ultimate decision to buy. We sat down with our development team and worked on how we could make it easier for the buyer and increase sales and limit customer disappointment. Using gaming technology, we developed an easy adaptable 3D map that we are offering to all our clients”. <br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;iTicket.Global mission is to use new technologies to develop the ticketing industry and increase revenues for event organisers and create a positive experience for the ticket buyer from the moment the ticket sales are announced.\n <br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;Elnur Akhundov, CTO iTicket.Global: “Our development department is constantly focused on innovation and developing tools that make the buying experience smooth. We want to help our clients sell their events by showcasing their product in the best possible way.”',
      author: 'Bettina Kuperman',
      createdAt: 'November 16, 2020',
      detailedViewHeader: 'First 3D map implementation in the ticketing world'
    }
  ];
}

