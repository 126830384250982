<footer class="mt-4">
  <div class="footer">
    <div class="img w-100 text-center">
      <img src="assets/images/general/iticket-gloabl-logo.svg" alt="footer-logo">
    </div>
    <p class="w-100 text-center mt-4">ITICKET <sup>&reg;</sup> is a registered trademark of
      <code style="color: #000000">&#x226A;iTICKET&#x226B;</code>
    </p>
  </div>
</footer>
