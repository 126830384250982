import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MediaDetailsComponent} from './media-details.component';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: MediaDetailsComponent
  }
];

@NgModule({
  declarations: [MediaDetailsComponent],
  imports: [
    CommonModule
  ],
  exports: [MediaDetailsComponent]
})
export class MediaDetailsModule { }
