import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { NetworkComponent } from './pages/network/network.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ContactUsComponent } from './shared/contact-us/contact-us.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {MediaDetailsComponent} from './pages/media-details/media-details.component';
import {MediaDetailsModule} from './pages/media-details/media-details.module';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'about',
    loadChildren: () => import('../app/pages/about/about.module').then(m => m.AboutModule)},
  { path: 'network',
    loadChildren: () => import('../app/pages/network/network.module').then(m => m.NetworkModule)
  },
  { path: 'products',
    loadChildren: () => import('../app/pages/products/products.module').then(m => m.ProductsModule)},
  { path: 'partners',
    loadChildren: () => import('../app/pages/partners/partners.module').then(m => m.PartnersModule)},
  { path: 'media',
    loadChildren: () => import('../app/pages/media/media.module').then(m => m.MediaModule)},
  { path: 'media/:id', component:  MediaDetailsComponent },
  { path: 'client', loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule) }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    // NetworkComponent,
    HeaderComponent,
    FooterComponent,
    ContactUsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    SharedModule,
    MediaDetailsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

