import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {DataPassService} from '../../services/data-pass.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-next-section',
  templateUrl: './next-section.component.html',
  styleUrls: ['./next-section.component.scss']
})
export class NextSectionComponent implements OnInit {

  @Input() nextSectionName;
  @Input() nextSectionUrl;
  @ViewChild('hoverSection') hoverSection: ElementRef;
  @HostListener('window:scroll') onScroll(e: Event): void {
    // this.animated = window.pageYOffset > 120;
    this.hoverSection.nativeElement.mouseenter;
  }

  constructor(private dataPassService: DataPassService,
              private router: Router) { }

  ngOnInit(): void {
  }

  changeRoute() {
    this.dataPassService.changeRouteMessage('route changed');
    this.router.navigate([this.nextSectionUrl]);

    // setTimeout( () => {
    // }, 0);
  }
}
