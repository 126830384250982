<div class="main-wrapper w-100">
    <div class="partial-section-wrapper w-100">
<!--    partial section starts-->
    <div class="container-fluid">
      <section class="row">
        <div class="page-header w-100 text-center d-flex justify-content-center mb-5">
          <div class="cube">
            <div class="rounded-bg-1"></div>
            <div class="rounded-bg-3"></div>
            <div class="rounded-bg-2"></div>
            <div class="cube-face cube-face-front"></div>
            <div class="cube-face cube-face-left"></div>
            <div class="cube-face cube-face-back"></div>
            <div class="cube-face cube-face-right"></div>
            <div class="cube-face cube-face-top"></div>
            <div class="cube-face cube-face-bottom"></div>
          </div>
          <p class="text" style="position:relative;">ITICKET.GLOBAL IS ONE OF <br>
            THE WORLD'S FASTEST <br> GROWING <span class="title-highlighted">TICKETING</span> AND <br>
            <span class="title-highlighted mt-2">TECHNOLOGY</span> BUSINESSES</p>
        </div>
        <div class="scroll-shower w-100 d-flex justify-content-center">
            <span class="d-inline-block scroll-wrapper">
              <span class="scroll-dot"></span>
            </span>
        </div>
      </section>
    </div>
    <div class="container sections-margin-top">
      <section class="row info-section-1">
        <div class="col-md-6">
          <p class="info-title" style="margin: 2rem;">Specialising in emerging markets, Iticket.global is dedicated to bringing smart and flexible ticketing and technology solutions to all corners of the world.</p>
          <p class="info-description">Iticket.global is much more than a ticketing company. We are technology driven trailblazers who are not afraid to challenge the status quo and pave new paths widening the use of new disruptive technologies globally.</p>
          <p class="info-description">We are e-commerce experts who provide our clients with easy to navigate platforms that are data-driven, responsive and secure.</p>
<!--          <button class="discover-button m-4">Discover services</button>-->
        </div>
        <div class="col-md-6 video-section">
          <video id='video'  preload='none' loop autoplay [muted]="true">
            <source id='mp4' src="assets/videos/Events%20for%20Global%20(951x595).mp4" type='video/mp4' />
          </video>
        </div>
      </section>
    </div>

    <div class="container-fluid sections-margin-top phone-section">
      <section class="row m-0">
        <div class="col-md-6 mobile-img-section d-flex justify-content-center">
          <img src="assets/images/general/phone-view.svg" alt="info" class="w-50">
        </div>
        <div class="col-md-6">
          <p class="info-title" style="margin: 2rem">Our mission is to increase our clients revenues and create a positive user experience for our customers.</p>
          <p class="info-description">The rules around innovation and customer experiences have fundamentally changed. They will never be the same again. The new digital reality is a fact that will continue to change the way businesses operate and consumers behave.</p>
          <p class="info-description">iTicket.global is a central partner to many industries that have been affected by Covid-19, and we work fast and flexibly with our partners to find new growth opportunities and exceed customer expectations.</p>
<!--          <button class="discover-button" style="margin-left: 2rem">Discover services</button>-->
        </div>
      </section>
    </div>

  </div>
  <div class="p-0 media-section">
    <section class="container">
    <div class="row">
       <div class="card mx-auto col-md-5 col-11 mb-5 p-0" *ngFor="let item of mediaContents.slice(0, 2); let i = index" [routerLink]="['/media', i +1]">
        <div class="wrapper">
          <div>
            <img src="{{item.img}}" alt="media" class="w-100">
          </div>
          <div class="data">
            <div class="content cursor-pointer">
              <h3 class="title"><a>{{item.header}}</a></h3>
              <p class="text">
                {{item.shortDescription}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  </div>

</div>

<app-next-section [nextSectionUrl]="'/about'" [nextSectionName]="'About us'"></app-next-section>
