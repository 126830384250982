<header>
  <nav class="navbar navbar-expand-lg">
    <a class="navbar-brand cursor-pointer" (click)="clickOnMenuItem('/')">
      <img src="assets/images/general/iticket-gloabl-logo.svg"  alt="iticket-global-logo" class="img-fluid">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
            aria-expanded="false" aria-label="Toggle navigation"  #togglerIcon (click)="hamburgerMenuOpenState = !hamburgerMenuOpenState">
      <span class="navbar-toggler-icon">
        <img src="assets/images/general/hamburger-menu.svg" alt="menu">
      </span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav m-auto">
        <li class="nav-item">
          <a class="nav-link cursor-pointer" [ngClass]="selectedRoute === '/about' ? 'active-url' : ''" (click)="clickOnMenuItem('/about')">About us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link cursor-pointer" [ngClass]="selectedRoute === '/network' ? 'active-url' : ''" (click)="clickOnMenuItem('/network')">Network</a>
        </li>
        <li class="nav-item">
          <a class="nav-link cursor-pointer" [ngClass]="selectedRoute === '/products' ? 'active-url' : ''" (click)="clickOnMenuItem('/products')">Products</a>
        </li>
        <li class="nav-item">
          <a class="nav-link cursor-pointer" [ngClass]="selectedRoute === '/partners' ? 'active-url' : ''" (click)="clickOnMenuItem('/partners')">Partners</a>
        </li>
        <li class="nav-item">
          <a class="nav-link cursor-pointer client-menu" [ngClass]="selectedRoute === '/client' ? 'active-url short_underline' : ''" (click)="clickOnMenuItem('/client')">Clients</a>
        </li>
        <li class="nav-item">
          <a class="nav-link cursor-pointer media-menu" [ngClass]="selectedRoute === '/media' ? 'active-url short_underline' : ''" (click)="clickOnMenuItem('/media')">Media</a>
        </li>
      </ul>
        <button class="my-2 my-sm-0" [ngClass]="contactUsRectangle ? 'btn-contact-us-products' : 'btn-contact-us'" type="button"
                data-toggle="modal" data-target="#exampleModalRight">Contact us</button>
    </div>
  </nav>
</header>

<!--btn-contact-us-->

<!-- Drawer -->
<div class="modal fade drawer right-align"  id="exampleModalRight" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document" style="margin-right: -17px !important; min-width: 45%;">
    <div class="modal-content">
      <div class="modal-header border-0">
        <p class="title" id="exampleModalLabel">CONT <br> ACT <br> US</p>
        <button type="button" class="close-button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex align-items-end">
        <div class="wrapper w-75">
          <p class="address">Address</p>
          <p class="description">
            iTicket.AZ LLC AF Mall 14th floor, office 36
            Azerbaijan, Baku, Samad Vurgun 34, AZ1000
          </p>
          <p class="phone">Phone number</p>
          <p class="phone-number">+994 77 600-00-60</p>
          <button class="find-map-button">
            <a href="https://www.google.com/maps/place/United+Sport/@40.376288,49.841252,17z/data=!4m5!3m4!1s0x0:0xa36277b8233dc65a!8m2!3d40.3764013!4d49.8411386?hl=az" target="_blank">
              Find on map
            </a>
          </button>
        </div>

      </div>
    </div>
  </div>
</div>
