<div class="container-fluid">
  <div class="text-center mb-5 mt-5">
    <h1 class="font-weight-bold mt-2">{{selectedPostDetails.detailedViewHeader}}</h1>
    <span class="text-secondary "><span style="color: #B699FF">
      {{selectedPostDetails.author}}
    </span> on {{selectedPostDetails.createdAt}}</span>

  </div>
   <div class="row">
    <div class="col-md-7 col-11 mx-auto mb-5">
      <div class="media-card">
        <img class="w-100 mb-4" src="{{selectedPostDetails.img}}" alt="media">
        <div class="description">
          <p class="description-texts" style="line-height: 1.5;">
            {{selectedPostDetails.shortDescription}}
          </p>
          <p class="description-texts" style="line-height: 1.5;" [innerHTML]="selectedPostDetails.longDescription">
          </p>
        </div>
        <div class="d-flex align-items-center social-networks">
          <span class="d-inline-block socials twitter mr-3">
            <img src="assets/icons/twitter-logo.svg" alt="share-on-twitter"> &nbsp; Tweet
          </span>
          <span class="d-inline-block socials facebook">
            <img src="assets/icons/facebook-logo.svg" alt="share-on-facebook"> &nbsp; Share on facebook
          </span>
        </div>
<!--        <div class="d-flex align-items-center my-4">-->
<!--          <div class="d-flex align-items-center mr-4">-->
<!--            <small class="mt-1" style="color: #9B5DE5;">6 min ago</small>-->
<!--          </div>-->
<!--          <div class="d-flex align-items-center">-->

<!--            <small class="mt-1" style="color: #9B5DE5;">3 comments</small>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

  </div>
</div>
