import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataPassService {
  private routeMessageSource = new BehaviorSubject('default');
  currentRouteMessage = this.routeMessageSource.asObservable();

  constructor() { }

  changeRouteMessage(message: string) {
    this.routeMessageSource.next(message);
  }
}
